import { createLazyFileRoute, useRouter } from "@tanstack/react-router";
import { AccessCreate, type AccessFormState, type AccessFormTypeId } from "../../../modules/access/AccessCreate.tsx";
import { replaceHistoryState } from "../../../utils/router/replaceHistoryState.ts";
import { useSimpleRouteParam } from "../../../utils/router/useSimpleRouteParam.tsx";

export const Route = createLazyFileRoute("/_main/access/create/$type")({
    component: Component,
});

function Component() {
    const router = useRouter();

    const [typeId, setTypeId] = useSimpleRouteParam(Route.useParams(), "type");

    // Save the form state into the browser history so it is restored when the user navigates back
    // XXX: This is probably overkill, maybe just block navigation when the form is in progress?
    function onSave(state: AccessFormState | undefined) {
        console.debug("Router history onSave: %o", state);
        replaceHistoryState(router, (s) => {
            s.accessCreate = state;
        });
    }
    const { accessCreate } = router.state.location.state;

    return (
        <AccessCreate
            typeId={typeId as AccessFormTypeId}
            onChangeTypeId={setTypeId}
            defaultValues={accessCreate}
            onSave={onSave}
        />
    );
}
