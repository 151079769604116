import { proxy, useSnapshot } from "valtio";
import { useOnce } from "./react/useOnce.ts";

/** Create a Valtio {@link proxy} scoped to the current component. */
export function useProxy<T extends object>(initial: () => T) {
    return useOnce(() => proxy<T>(initial()));
}

/**
 * Use an auto-tracking Valtio {@link proxy} state object.
 *
 * (tracking means that the component will re-render when the state changes)
 *
 * @param initial Function that returns the initial state object
 * @returns `[snapshot, state]` - access or destructure `snapshot` to get auto-tracked
 *          variables, use `state` to modify it (and for untracked read access)
 * @example
 *     const [{ count }, state] = useProxyState(() => ({ count: 0 }));
 *     return <button onClick={() => ++state.count}>Add 1 to {count}</button>;
 */
export function useProxyState<T extends object>(initial: () => T) {
    const state = useProxy<T>(initial);
    const snapshot = useSnapshot(state);
    return [snapshot, state] as const;
}
