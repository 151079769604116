import { useAtomValue, useSetAtom } from "jotai/react";
import { atom, type PrimitiveAtom } from "jotai/vanilla";
import { useEffect } from "react";
import { useOnce } from "./useOnce.ts";

export type Trigger = PrimitiveAtom<number>;

export type UseTrigger = [Trigger, () => void];

// Trigger to use when no trigger is provided.
const dummyTrigger = atom(0);

/**
 * A representation of a change-type event that components can listen to using {@link useWhenTriggered}.
 *
 * Useful for sending "events" from parent to children (this is the opposite of children-to-parent communication, ie.
 * the children having `onSomeEvent` props).
 *
 * @returns `[somethingTrigger, triggerSomething]` - The value to pass to children, and a function to call to trigger the event.
 */
export function useTrigger(): UseTrigger {
    // Keep an "epoch count" in an atom that other components can listen to
    const triggerCountAtom = useOnce(() => atom(0));

    const setTriggerCount = useSetAtom(triggerCountAtom);
    return [triggerCountAtom, () => setTriggerCount((count) => count + 1)];
}

/** Call {@link callback} when {@link trigger} is triggered. */
export function useWhenTriggered(trigger: Trigger | undefined, callback: () => void) {
    const triggerCount = useAtomValue(trigger ?? dummyTrigger);
    useEffect(() => {
        if (triggerCount !== 0) {
            callback();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger, triggerCount]);
}
