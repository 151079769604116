import T from "./forms/TextField.module.css";

import { focusSafely } from "@react-aria/focus";
import { useState, type ForwardedRef, type ReactNode } from "react";
import { useKeyboard, useObjectRef } from "react-aria";
import { FieldError, Input, TextField } from "react-aria-components";
import { splice } from "remeda";
import { useWhenTriggered, type Trigger } from "../utils/react/eventTrigger.ts";
import { DeleteButton } from "./button/DeleteButton.tsx";
import { WButton } from "./button/WButton";
import { FieldButtonGroup } from "./forms/FieldButtonGroup.tsx";
import { WTable } from "./table/WTable.tsx";

interface ListCommonProps {
    isDisabled?: boolean;
    /** Error to show (from parent / outside) */
    errorMessage?: ReactNode;

    validationTrigger?: Trigger;
}

export interface EditableListRenderProps<T> {
    items: T[];
    /** Call this to delete an item */
    onDelete: (index: number) => void;
}

export interface EditableListInputProps<T> extends ListCommonProps {
    isDisabled: boolean;
    /** Call this to add an item */
    onAdd: (item: T) => void;
}

export interface EditableListProps<T> extends ListCommonProps {
    items?: T[];
    /** Called when the list changes */
    onChange?: (items: T[]) => void;

    /** Render the list items */
    children: (props: EditableListRenderProps<T>) => ReactNode;
    /** Render the input to add a new item */
    addInput: (props: EditableListInputProps<T>) => ReactNode;
}

export function EditableList<T>({
    items = [],
    onChange,
    addInput,
    children,
    errorMessage,
    isDisabled = false,
}: EditableListProps<T>) {
    // TODO: Appropriate styles for error here
    return (
        <div>
            {addInput({
                isDisabled,
                errorMessage,
                onAdd: (item) => onChange?.([...items, item]),
            })}

            <div className="react-aria-FieldError">{errorMessage}</div>

            {children({
                items,
                onDelete: (index) => onChange?.(splice(items, index, 1, [])),
            })}
        </div>
    );
}

function notEmptyString(value: string) {
    return value.trim() !== "";
}

interface StringAdderProps extends EditableListInputProps<string> {
    defaultValue?: string;
    validate?: (value: string) => boolean;
    inputRef?: ForwardedRef<HTMLInputElement>;
    onChange?: (value: string) => void;
}

function StringAdder({
    onAdd,
    errorMessage,
    isDisabled,
    onChange,
    inputRef: inputRefProp,
    defaultValue = undefined,
    validate = notEmptyString,
    validationTrigger,
}: StringAdderProps) {
    //#region Hooks

    /** `undefined` if the input has not been modified yet */
    const [value, setValue] = useState<string>(defaultValue ?? "");
    const [inValidationMode, setInValidationMode] = useState(false);

    const inputRef = useObjectRef<HTMLInputElement>(inputRefProp);

    const { keyboardProps } = useKeyboard({
        // NB: must be onKeyDown, not onKeyUp, to prevent the default behavior of submitting the form
        onKeyDown: (e) => {
            if (e.key === "Enter") {
                // Prevent parent form from submitting
                e.preventDefault();
                onAddPressed();
            } else if (e.key === "Escape" && value) {
                e.preventDefault();
                handleSetValue("");
            }
        },
    });

    useWhenTriggered(validationTrigger, () => {
        console.debug("StringAdder: Validation trigger");
        setInValidationMode(true);
    });

    //#endregion Hooks

    // NB: Only consider `inputRef` invalid if the ref exists
    // NB: Must use `validity.valid` instead of `checkValidity()` because react-aria intercepts the latter and it will cause React `setState` errors
    const isValidToAdd = validate(value) && inputRef.current?.validity.valid !== false;

    /** Only show as invalid if the input has not been modified */
    const showIsInvalid = !isValidToAdd && (value !== "" || inValidationMode);
    const isExternalInvalid = errorMessage != null;
    const enableErrorStyle = inValidationMode && (showIsInvalid || isExternalInvalid);

    function onAddPressed() {
        setInValidationMode(true);

        if (isValidToAdd) {
            handleSetValue("");
            // NB: We call `onAdd` after resetting the value to ensure that validation rules in the parent component are triggered with the complete state (ie. the input is empty)
            onAdd(value);
        }

        const input = inputRef.current!;
        focusSafely(input);
    }

    function handleSetValue(value: string) {
        setInValidationMode(false);

        setValue(value);
        onChange?.(value);
    }

    const validationMessage = inputRef.current?.validationMessage;

    return (
        <TextField
            className={T.TextField}
            type="email"
            onChange={handleSetValue}
            // "aria" to not prevent any parent form from submitting if we have errors
            validationBehavior="aria"
            // NB: With validationBehavior="aria", we must always set `isInvalid` manually
            isInvalid={showIsInvalid}
            isDisabled={isDisabled}
            aria-label="Enter value"
        >
            <FieldButtonGroup>
                <Input
                    className={T.Input}
                    // NB: we do not use `isInvalid` because that would create a cyclic dependency, since we check the inputs validity in `isValid` above
                    // As a workaround, setting `aria-invalid` here forces the appropriate styles but does not change the actual invalidity state.
                    aria-invalid={enableErrorStyle}
                    ref={inputRef}
                    value={value}
                    {...keyboardProps}
                />
                <WButton type="button" isDisabled={isDisabled} action={onAddPressed}>
                    Add
                </WButton>
            </FieldButtonGroup>
            {enableErrorStyle && <FieldError>{validationMessage}</FieldError>}
        </TextField>
    );
}

export interface EditableStringListProps extends Omit<EditableListProps<string>, "addInput" | "children"> {
    onInputChange?: (value: string) => void;
    inputRef?: StringAdderProps["inputRef"];
}

export function EditableStringList({ inputRef, onInputChange, validationTrigger, ...props }: EditableStringListProps) {
    return (
        <EditableList
            addInput={(inputProps) => (
                <StringAdder
                    inputRef={inputRef}
                    validationTrigger={validationTrigger}
                    onChange={onInputChange}
                    {...inputProps}
                />
            )}
            {...props}
        >
            {({ items, onDelete }) =>
                !!items.length && (
                    <WTable>
                        <thead>
                            <tr>
                                <th>Email</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item, index) => (
                                <tr key={index}>
                                    <td>{item}</td>
                                    <td className="text-right">
                                        <DeleteButton inTable action={() => onDelete(index)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </WTable>
                )
            }
        </EditableList>
    );
}

export function UncontrolledEditableStringList({
    defaultItems = [],
    onChange,
    ...props
}: Omit<EditableStringListProps, "items"> & {
    defaultItems?: string[];
}) {
    const [items, setItems] = useState(defaultItems);
    return (
        <EditableStringList
            items={items}
            onChange={(newItems) => {
                setItems(newItems);
                onChange?.(newItems);
            }}
            {...props}
        />
    );
}
